/* eslint-disable */
import Vue from 'vue';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { ModuleState, RootState } from '@/store/interfaces';
import { api } from '@/services/api';
import instance from '@/main';
import axios from 'axios';
import { i18n } from '@/i18n';
import { nanoid } from 'nanoid';
import { store } from '@/store';

const dispatchToast = (
  { title, message }: {
    title: string;
    message: string;
  },
) => {
  (instance as any).$toasts.toast({
    id: nanoid(),
    intent: 'error',
    title,
    message,
  });
};

export interface State extends ModuleState {
  isFetching: boolean;
  coins: any;
  leaderboards: any;
}

export default {
  namespaced: true,

  state: () => ({
    isFetching: false,
    coins: [],
    leaderboards: [],
  }) as State,

  actions: {
    async fetchCoinsList({ state, getters, commit, dispatch }) {
      Vue.set(state, 'isFetchingUser', true);
      let coinsFilter = '';
      getters.getAssetsCoins.forEach(({ symbol }: { symbol: string }) => {
        coinsFilter += `&filter[symbols][]=${symbol}`;
      });
      try {
        const { data: { data } } = await api.find(`v1/cryptocurrencies?page[size]=150&page[number]=1${coinsFilter}`);
        Vue.set(state, 'coins', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetchingUser', false);
      }
    },
    async fetchLeaderboards({ state, commit, dispatch }, type) {
      Vue.set(state, 'isFetching', true);
      const endpoint = type
        ? `v1/leaderboard?sort_by=${type}&sort_dir=desc`
        : 'v1/leaderboard';

      try {
        const { data: { data } }  = await api.find(endpoint);
        Vue.set(state, 'leaderboards', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async getCoinsList({ state, commit, dispatch }, token) {
      Vue.set(state, 'isFetchingUser', true);
      const url = 'https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?CMC_PRO_API_KEY=b76f010b-00d1-4a7b-b247-494b3dde5709&start=1&limit=10';
      try {
        const { data: { data } }  = await axios.get(`${url}`, {
          headers: {
            accept: 'application/vnd.api+json, application/json',
            'content-type': 'application/vnd.api+json',
            'Access-Control-Allow-Origin': '*',
          },
        });
        Vue.set(state, 'coins', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetchingUser', false);
      }
    },
  } as ActionTree<State, RootState>,

  getters: {
    isFetching: state => state.isFetching,
    getCoinsList: state => state.coins,
    getLeaderboards: state => state.leaderboards,
    getCoins: (state) => {
      return [
        { symbol: 'SNSY', value: 366870 },
        { symbol: 'AGENT', value: 57071 },
        { symbol: 'PEAQ', value: 31846 },
        { symbol: 'SUPRA', value: 1199501 },
        { symbol: 'HOPPY', value: 17396734 },
        { symbol: 'PENDLE', value: 715 },
        { symbol: 'ONDO', value: 4422 },
        { symbol: 'TURBO', value: 500000 },
        { symbol: 'ATH', value: 58002 },
        { symbol: 'IO', value: 1054 },
        { symbol: 'ABT', value: 1962 },
        { symbol: 'GFI', value: 2018 },
        { symbol: 'INJ', value: 239 },
        { symbol: 'RNDR', value: 947 },
        { symbol: 'AITECH', value: 42991 },
        { symbol: 'AGI', value: 42775 },
      ];
    },
    getAssetsCoins: (state) => {
      return [
        { symbol: 'SNSY' },
        { symbol: 'AGENT' },
        { symbol: 'PEAQ' },
        { symbol: 'SUPRA' },
        { symbol: 'HOPPY' },
        { symbol: 'PENDLE' },
        { symbol: 'ONDO' },
        { symbol: 'TURBO' },
        { symbol: 'ATH' },
        { symbol: 'IO' },
        { symbol: 'ABT' },
        { symbol: 'CPOOL' },
        { symbol: 'GFI' },
        { symbol: 'METIS' },
        { symbol: 'ZKML' },
        { symbol: 'AXGT' },
        { symbol: 'AIOZ' },
        { symbol: 'OPSEC' },
        { symbol: 'AIT' },
        { symbol: 'MIND' },
        { symbol: 'AITECH' },
        { symbol: 'AGI' },
        { symbol: 'NAKA' },
        { symbol: 'NXRA' },
        { symbol: 'RNDR' },
        { symbol: 'CRU' },
        { symbol: 'VRA' },
        { symbol: 'PYTH' },
        { symbol: 'QANX' },
        { symbol: 'MANTA' },
        { symbol: 'ABT' },
        { symbol: 'ZETA' },
        { symbol: 'SERSH' },
        { symbol: 'ALU' },
        { symbol: 'TET' },
        { symbol: 'DOME' },
        { symbol: 'CPO' },
        { symbol: 'FLUX' },
        { symbol: 'MAVIA' },
        { symbol: 'BLZ' },
        { symbol: 'LIT' },
        { symbol: 'CGPT' },
        { symbol: 'JOE' },
        { symbol: 'RVF' },
        { symbol: 'INJ' },
        { symbol: 'ARKM' },
        { symbol: 'DCK' },
        { symbol: 'OCTA' },
        { symbol: 'ORDS' },
        { symbol: 'GLQ' },
      ];
    },
    isMobileView: () => {
      const windowWidth = window.innerWidth;
      return windowWidth < 500;
    },
  } as GetterTree<State, RootState>,

  mutations: {
    setErrors(state: State, reason: any) {
      const { status, data: { errors } } = reason;
      const errorObject = [] as any;

      if (errors && errors.length) {
        errors.forEach((item: any) => dispatchToast({
          title: item.title || 'Error',
          message: item.detail || 'Something went wrong. Please try again',
        }));
      }
    },
  } as MutationTree<State>,
};
